// @ts-check
import { createScript, hydrated } from "@sciam/shared";

import { client as adsConfig } from "./ads";
import { client as chargebeeConfig } from "./chargebee";
import { client as chartbeatConfig, clientMab as chartbeatMabConfig } from "./chartbeat";
import customEvents from "./custom";
import { client as gtmConfig } from "./gtm";
import { client as idpConfig } from "./idp";
import { client as onetrustConfig } from "./onetrust";
import { client as onetrustBetaConfig } from "./onetrust-beta";
import { client as pianoConfig } from "./piano";

export async function load({ ads = true, tracking = true, ecomm = true } = {}) {
  const loadOneTrust = window.location.search.match(/debug=tcfv2/)
    ? createScript(onetrustBetaConfig)
    : createScript(onetrustConfig);
  const loadPianoScript = createScript(pianoConfig);
  const loadChargebee = ecomm && createScript(chargebeeConfig);
  const loadGtm = tracking && createScript(gtmConfig);
  const loadSciAds = ads && createScript(adsConfig);
  const loadIDP = createScript(idpConfig);

  // Load OneTrust, Piano, Ads, and Chargebee in parallel
  // Note, browsers will prioritize bandwidth based in part by the order these script tags are created in.
  const onetrust = loadOneTrust();
  const piano = loadPianoScript();
  const sciads = ads && loadSciAds();
  const idp = loadIDP();

  // Simplest possible check for the Chargebee feature flag
  const chargebeeFlag =
    window.location.search.includes("flag=chargebee") ||
    !!window.localStorage.getItem("sciam:chargebee") ||
    import.meta.env.PUBLIC_ENTITLEMENTS_PROVIDER === "chargebee";
  const chargebee = chargebeeFlag && ecomm && loadChargebee();

  // Wait for hydration before loading GTM to prioritize performance
  await Promise.allSettled([onetrust.loaded, hydrated()]);

  const gtm = tracking && loadGtm();

  // Load items that require consent. Nothing else will wait for them.
  window.consentQueue.push(({ consent, isFirstRun }) => {
    // Load Chartbeat if we have consent and it's the callback's first run
    if (!tracking || !isFirstRun || !consent.performance) return;

    const chartbeat = createScript(chartbeatConfig)();
    const chartbeatMab = createScript(chartbeatMabConfig)();

    Promise.allSettled([chartbeat.loaded, chartbeatMab.loaded]).then(() => {
      console.log("[loader] chartbeat loaded");
    });
  });

  customEvents();

  // Wait for all scripts' `onload` events to fire
  await Promise.allSettled([
    onetrust.loaded,
    piano.loaded,
    idp.loaded,
    tracking && gtm.loaded,
    ads && sciads.loaded,
    ecomm && chargebee.loaded,
  ]);

  console.log("[loader] all scripts loaded");

  // Wait for all scripts' `readyWhen` conditions to be met
  await Promise.allSettled([
    onetrust.ready,
    piano.ready,
    idp.ready,
    tracking && gtm.ready,
    ads && sciads.ready,
    ecomm && chargebee.ready,
  ]);

  console.log("[loader] all scripts ready");

  // Generate a report of all scripts' loaded and ready states
  // This is only intended for debugging purposes
  window.scriptReport = await generateScriptReport({
    onetrust,
    piano,
    sciads,
    gtm,
    idp,
  });

  return window.scriptReport;
}

/**
 * Generate a report of all scripts' loaded and ready states
 *
 * Though this returns a promise, it'll resolve immediately because all the promises it awaits are already resolved
 * @returns {Promise<Record<string, { loaded: boolean, ready: boolean }>>}
 */
async function generateScriptReport(scripts = {}) {
  const scriptNames = Object.keys(scripts);

  return await Promise.allSettled(
    // Generate an array of Promises that resolve to an object with the script's loaded and ready state
    scriptNames.map(async (scriptName) => {
      let value = scripts[scriptName];
      if (!value) return { loaded: false, ready: false };
      return {
        loaded: await value.loaded.then(() => true).catch(() => false),
        ready: await value.ready.then(() => true).catch(() => false),
      };
    }),
    // Transform the result into an object with the script name as the key
  ).then((results) => {
    return results.reduce((acc, result, index) => {
      acc[scriptNames[index]] = result;
      return acc;
    }, {});
  });
}
