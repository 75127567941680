import React from "react";
import type { ComposableFunction } from "./ProviderComposer";
import Compose from "./ProviderComposer";

import { ProvidePiano, ProvidePianoEnv, ProvideUserAccess } from "@sciam/piano/react";
import { ProvideEnvironment } from "~core/hooks/use-environment";
import { ProvideFlags } from "~core/hooks/use-flags";
import { PageContext, PageContextProvider } from "~core/hooks/use-page";
import { AccessProvider } from "~features/access";
import { ProvideAuth0 } from "~features/auth/provider";
import { ProvideChargebee } from "~features/chargebee/hooks/use-chargebee";
import { ProvideMediaState } from "~features/media/hooks/use-media-state";
import { OverlayProvider } from "~features/paywall/useOverlay";
import { AuthSyncProvider } from "~features/piano/hooks/use-piano-auth-sync";

interface AppProvidersProps {
  children: React.ReactNode;
  bundle: string;
  pageData: PageContext["pageData"];
  injectedProviders?: ComposableFunction[];
}
export default function AppProviders({
  pageData,
  children,
  bundle,
  injectedProviders = [],
}: AppProvidersProps) {
  const pageContext = { pageData };

  return (
    <Compose
      components={[
        // @TODO: Some of these providers can be consolidated and/or rebuilt with a proper state management library

        // Core providers
        (child) => <PageContextProvider pageContext={pageContext} children={child} />,

        // Flags provider - Must go first because services depend on flags
        (child) => <ProvideFlags children={child} />,

        // Service connectors
        (child) => <ProvidePianoEnv children={child} />,
        (child) => <ProvideAuth0 children={child} />,
        (child) => <ProvideChargebee children={child} />,

        // This provider utilizes the above contexts to determine the final render environment
        (child) => <ProvideEnvironment children={child} />,

        // The AuthSyncProvider is responsible for syncing Auth0, Piano, and SciAm access tokens
        (child) => (
          <AuthSyncProvider
            children={child}
            force={bundle === "authentication" || bundle === "success"}
          />
        ),

        // New server-side entitlements provider.
        (child) => <AccessProvider children={child} />,

        // Additional providers injected by bundles
        ...injectedProviders,

        // User state providers
        (child) => <ProvidePiano children={child} />,
        (child) => <ProvideUserAccess children={child} />,
        (child) => <ProvideMediaState children={child} />,
        (child) => <OverlayProvider children={child} />,
      ]}
    >
      <>{children}</>
    </Compose>
  );
}
